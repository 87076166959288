import _ from "lodash";
export const obterPastosPorRetiroSelecionado = (retiroSelecionado, pastos) => {
  return retiroSelecionado === -1
    ? pastos
    : pastos.filter(function (pasto) {
      return pasto.IdRetiro === retiroSelecionado;
    });
};

export const obterPastosComLote = (farolPasto) => {
  return farolPasto.filter(function (farol) {
    return farol.Valor.Lotes.length > 0;
  });
};

export const obterPastosDoRetiroComLotes = (pastosRetiro, pastosComLotes) => {
  const pastosDoRetiroComLotes = [];

  _.forEach(pastosRetiro, (pastoRetiro) => {
    _.some(pastosComLotes, (pastoLote) => {
      if (pastoLote.IdPasto === pastoRetiro.IdPasto) {
        pastosDoRetiroComLotes.push(pastoLote);
        return true;
      }
    });
  });

  return pastosDoRetiroComLotes;
};

export const obterSomaDeCabecasDeGadoPorCategoria = (categoria, pastos) => {
  let quantidadeDeCabecas = 0;

  _.forEach(pastos, (pasto) => {
    _.forEach(pasto.Valor.Lotes, (lote) => {
      if (lote.IdCategoriaAnimal === categoria.IdCategoriaAnimal) {
        quantidadeDeCabecas += lote.Quantidade;
      }
    });
  });
  return quantidadeDeCabecas;
};

export const obterSomaDeTodasAsCategorias = (categorias, pastos) => {
  let rebanhoTodasCategorias = 0;
  _.forEach(categorias, (categoria) => {
    rebanhoTodasCategorias =
      rebanhoTodasCategorias +
      obterSomaDeCabecasDeGadoPorCategoria(categoria, pastos);
  });
  return rebanhoTodasCategorias;
};

export const obterPesoMedioTodasCategorias = (pastos, currais) => {
  let pesoMedioTodasCategorias = 0;
  let quantidadeDeCabecas = 0;

  currais.forEach((curral) => {
    curral.Valor.Lotes.forEach(lote => {
      pesoMedioTodasCategorias += lote.PesoVisual * lote.Quantidade;
      quantidadeDeCabecas += lote.Quantidade;
    });
  });

  pastos.forEach((pasto) => {
    pasto.Valor.Lotes.forEach((lote) => {
      pesoMedioTodasCategorias += lote.PesoVisual * lote.Quantidade;
      quantidadeDeCabecas += lote.Quantidade;
    });
  });
  if (quantidadeDeCabecas === 0) return 0;
  return pesoMedioTodasCategorias / quantidadeDeCabecas;
};

export const obterSomaDeAnimaisNoCurral = (currais) => {
  let quantidadeDeCabecas = 0;
  _.forEach(currais, (curral) => {
    _.forEach(curral.Valor.Lotes, (lote) => {
      quantidadeDeCabecas += lote.Quantidade;
    });
  });
  return quantidadeDeCabecas;
};

export const obterQuantidadePastosCurralEPesoMedio = (
  pastos,
  curraisRetiroComLotes,
  categoria
) => {
  let quantidadeDeCabecasNoCurral = 0;
  let quantidadeDeCabecas = 0;
  let pesoMedio = 0;

  _.forEach(pastos, (pasto) => {
    _.forEach(pasto.Valor.Lotes, (lote) => {
      if (lote.IdCategoriaAnimal === categoria.IdCategoriaAnimal) {
        quantidadeDeCabecas += lote.Quantidade;
        pesoMedio += lote.PesoVisual * lote.Quantidade;
      }
    });
  });

  _.forEach(curraisRetiroComLotes, (curral) => {
    _.forEach(curral.Valor.Lotes, (lote) => {
      if (lote.IdCategoriaAnimal === categoria.IdCategoriaAnimal) {
        quantidadeDeCabecasNoCurral += lote.Quantidade;
        pesoMedio += lote.PesoVisual * lote.Quantidade;
      }
    });
  });

  let quantidadeDeCabecasTotal = quantidadeDeCabecas + quantidadeDeCabecasNoCurral;

  if (quantidadeDeCabecasTotal > 0) {
    pesoMedio = pesoMedio / quantidadeDeCabecasTotal;
  }
  return { quantidadeDeCabecas, quantidadeDeCabecasNoCurral, pesoMedio };
};
