import _ from "lodash";
import novatentativa from "../lib/novatentativa";
import BaseService from "./BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos`;
class PastoService extends BaseService {
  async obtemPastoPorId(idPasto) {
    const filtro = { where: { IdPasto: idPasto }, include: "Cocho" };
    const parametrosURL = this.montaFiltro(filtro);
    const urlServico = this.montaURL(`${URL_BASE}/`, parametrosURL);
    const resultado = await this.get(urlServico);
    return resultado.data[0];
  }

  async listaPastosPorIdFazenda(
    IdFazenda,
    camposRetornar = [],
    includeFiltro = ""
  ) {
    try {
      const filter = { where: { IdFazenda } };
      if (camposRetornar.length) {
        filter.attributes = camposRetornar;
      }
      if (includeFiltro.length) {
        filter.include = includeFiltro;
      }
      const filtro = this.montaFiltro(filter);
      const parametrosURL = filtro;
      const urlServico = this.montaURL(URL_BASE, parametrosURL);
      return await this.get(urlServico);
    } catch (error) {
      console.error(error);
      throw new Error(
        "Ocorreu um erro ao buscar os pastos para a fazenda selecionada."
      );
    }
  }

  async cadastrarPasto(dataPasto) {
    try {
      Object.keys(dataPasto).forEach((key) => {
        if (dataPasto[key] === "") {
          dataPasto[key] = null;
        }
      });
      const response = await this.post(
        `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos`,
        dataPasto
      );
      return response;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }

  /* istanbul ignore next */
  async atualizarPasto(idPasto, dataPasto) {
    try {
      dataPasto = _.omit(dataPasto, ["IdFazenda"]);
      Object.keys(dataPasto).forEach((key) => {
        if (dataPasto[key] === "") {
          dataPasto[key] = null;
        }
      });
      const response = await this.put(
        `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos/${idPasto}`,
        dataPasto
      );
      return response;
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.errorCode === 2
      ) {
        throw new Error(
          "Erro ao alterar o retiro de um pasto cadastrado em um módulo."
        );
      } else if (error && error.response && error.response.data) {
        throw new Error(error.response.data);
      } else {
        console.error(error);
        throw new Error("Erro ao atualizar pasto.");
      }
    }
  }

  /* istanbul ignore next */
  async deletarPasto(idPasto) {
    const response = await this.delete(
      `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos/${idPasto}`
    ).catch((error) => {
      throw error.response.data;
    });

    return response;
  }

  /**
   * FIXME: Retirar regra de csv do service.
   * Criar um outro arquivo para concentrar as regras de CSV.
   * O service deve ter apenas regras de acesso a dados, e não deve conhecer CSV,
   * ou fazer downloads na tela ou sequer tratar os dados que recebe.
   */
  async salvaMultiplosPastosFazenda(pastosSalvar) {
    try {
      if (pastosSalvar.length) {
        const tentativa = new novatentativa(2);
        const pastosInseridos = await Promise.all(
          pastosSalvar.map((pasto, index) => {
            let pastoSalvar = _.omit(pasto, ["nomeModulo", "nomeRetiro"]);
            return tentativa.postServicoUrl(URL_BASE, pastoSalvar);
          })
        );
        return pastosInseridos;
      }
    } catch (error) {
      console.error("error :", error);
    }
  }

  /**
   * FIXME: Retirar regras de tratamento de dados
   */
  async atualizaMultiplosPastos(pastosAtualizar) {
    try {
      const pastosAtualizados = await Promise.all(
        pastosAtualizar.map((pastoAtualizar, index) => {
          const IdPasto = pastoAtualizar.IdPasto;
          pastoAtualizar = _.omit({ ...pastoAtualizar }, ["IdPasto"]);
          Object.keys(pastoAtualizar).forEach((key) => {
            if (pastoAtualizar[key] === "") {
              pastoAtualizar[key] = null;
            }
          });
          return this.put(`${URL_BASE}/${IdPasto}`, pastoAtualizar);
        })
      );
      return pastosAtualizados;
    } catch (erro) {
      return { erro };
    }
  }

  /* istanbul ignore next */
  async atualizarOuCriarCocho(idPasto, idCocho, cocho) {
    /* istanbul ignore next */
    if (idCocho) {
      if (!cocho.CochoCreep) {
        await this.put(
          `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos/${idPasto}/cocho`,
          cocho
        );
      } else {
        await this.put(
          `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos/${idPasto}/cocho?filter={"where":{"IdCocho":${idCocho}}}`,
          cocho
        );
      }
    } else {
      /* istanbul ignore next */
      await this.post(
        `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos/${idPasto}/cocho`,
        cocho
      );
    }
  }
}

export default new PastoService();
