import _ from "lodash";
import { retornoChamadaObterPesoProjetadoPorLotes } from "./tests/mocks/tabelaProjecaoPesoMock.ig";
import { verificaExisteSubString } from "../shared/utils";
import dayjs from "dayjs";

export const DADO_INDISPONIVEL = "Não disponível";
export const MESES_ANO = [
  "Janeiro",
  "Fevereiro",
  "Marco",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const MESES_INDEXADOS = {
  0: MESES_ANO[0],
  1: MESES_ANO[1],
  2: MESES_ANO[2],
  3: MESES_ANO[3],
  4: MESES_ANO[4],
  5: MESES_ANO[5],
  6: MESES_ANO[6],
  7: MESES_ANO[7],
  8: MESES_ANO[8],
  9: MESES_ANO[9],
  10: MESES_ANO[10],
  11: MESES_ANO[11],
};

/**
 * Com base em uma comparação dos meses com o que esta
 * no array ele busca e define qual a quantidade de dias
 * daquele mês
 */
export const pegarNumeroDosDiasMes = (nomeMes, arrayMeses) => {
  let keyMes;
  arrayMeses.forEach((valor, key) => {
    if (nomeMes === valor) keyMes = key;
  });
  if (keyMes !== null) {
    const numeroDias = new Date(
      new Date().getFullYear(),
      keyMes + 1,
      0
    ).getDate();
    return numeroDias;
  } else {
    return "";
  }
};

export const filtroLotePorTexto = (lote, textoFiltrar) => {
  const { categoria, pasto, retiro } = lote;
  let textoIgualCategoria, textoIgualPasto, textoIgualRetiro;
  if (categoria && categoria.Nome) {
    textoIgualCategoria = verificaExisteSubString(categoria.Nome, textoFiltrar);
  }
  if (pasto && pasto.NomeReduzido) {
    const pastoNomeCompleto = `${pasto.Nome} ${pasto.NomeReduzido}`;
    textoIgualPasto = verificaExisteSubString(pastoNomeCompleto, textoFiltrar);
  }
  if (retiro && retiro.Nome) {
    const retiroNomeCompleto = `${retiro.Nome} ${retiro.NomeReduzido}`;
    textoIgualRetiro = verificaExisteSubString(
      retiroNomeCompleto,
      textoFiltrar
    );
  }
  const respostaFiltro =
    textoFiltrar === "" ||
    !!textoIgualCategoria ||
    !!textoIgualPasto ||
    !!textoIgualRetiro;
  return respostaFiltro;
};

export const filtraListaLotes = (list, textoFiltrar) => {
  let lotesFiltrados;
  if (list && list.length) {
    lotesFiltrados = list.filter((lote) => {
      return filtroLotePorTexto(lote, textoFiltrar);
    });
    return lotesFiltrados;
  }
  return [];
};

export const confereExistenciaDeDataPesagemIncorreto = (lotes) => {
  let desabilitarBotaoSalvar = false;
  lotes.forEach((lote) => {
    desabilitarBotaoSalvar =
      !desabilitarBotaoSalvar && !lote.EstadoDataPesagem
        ? true
        : desabilitarBotaoSalvar;
  });
  return desabilitarBotaoSalvar;
};

export const extrairPesoProjetadoProximoSemestre = () => {
  return retornoChamadaObterPesoProjetadoPorLotes;
};

export const mockOperationObterProjecaoPesoLotes = (lotes) => {
  let respostaServicoProjecaoPeso = extrairPesoProjetadoProximoSemestre();
  lotes.forEach((lote) => {
    if (lote.IdLote % 3)
      respostaServicoProjecaoPeso[lote.IdLote] =
        respostaServicoProjecaoPeso["1"];
  });
  return adicionarCurvaProjecaoPesoPorLote(lotes, respostaServicoProjecaoPeso);
};

export const adicionarCurvaProjecaoPesoPorLote = (
  lotes,
  projecaoPesoPorLotes
) => {
  let lotesComProjecaoPeso = lotes.map((lote) => {
    let pesoProjetadoLote = projecaoPesoPorLotes[lote.IdLote];
    lote.ProjecaoPesoSemestre = pesoProjetadoLote ? pesoProjetadoLote : {};
    return lote;
  });
  return lotesComProjecaoPeso;
};

/**
 * Gera a menssagem mostrada no modal, com base nos dados da projeção do Ganho de peso
 * que foi inserido.
 */

export const padronizarMensagensProjecao = (projecaoGanhoPesoSemestre) => {
  const valoresSemestre = projecaoGanhoPesoSemestre
    ? projecaoGanhoPesoSemestre
    : {};
  let mensagensSemestre = [];

  for (let i = 0; i < 6; i++) {
    let mesAtual = dayjs().add(i, "months").month();
    let nomeMesAtual = MESES_INDEXADOS[mesAtual];
    let mensagem = {};
    const numeroDia = parseInt(pegarNumeroDosDiasMes(nomeMesAtual, MESES_ANO));
    let nomeMes = nomeMesAtual === "Marco" ? "Março" : nomeMesAtual;
    mensagem.Mes = numeroDia + "/" + nomeMes;
    mensagem.Peso = parseFloat(valoresSemestre[nomeMesAtual]).toFixed(2);
    mensagensSemestre.push(mensagem);
  }
  return mensagensSemestre;
};

export const padronizarMensagensProjecaoPesoSemestre = (
  projecaoGanhoPesoSemestre
) => {
  const valoresSemestre = projecaoGanhoPesoSemestre
    ? projecaoGanhoPesoSemestre
    : {};
  let meses = Object.keys(valoresSemestre);
  let mensagensSemestre = [];
  meses.forEach((mes) => {
    let mensagem = {};
    if (mes !== "IdLote") {
      mensagem.Mes = mes;
      mensagem.Peso = valoresSemestre[mes];
      mensagensSemestre.push(mensagem);
    }
  });
  return mensagensSemestre;
};

export const obterMensagemMesAtualProjecaoPeso = (pesoProjetado) => {
  const d = new Date();
  const mesAtual = d.getMonth();
  const currentMonth = MESES_ANO[mesAtual];
  const monthName = currentMonth === "Marco" ? "Março" : currentMonth;
  let mensagem;
  if (pesoProjetado) {
    mensagem = `${monthName} - ${pesoProjetado.toFixed(2)} kg`;
  } else {
    mensagem = DADO_INDISPONIVEL;
  }
  return mensagem;
};

export const obtemEstiloLink = (pesoProjetado) => {
  if (!pesoProjetado) {
    return {
      textDecoration: "line-through",
    };
  } else {
    return {};
  }
};

export const condicaoToolTip = (toggle, tipoOperacao) => {
  return toggle && tipoOperacao === 7;
};

export const mapeiaRetirosLotes = (lotes, pastos, retiros) => {
  const mapearRetiros = !!lotes.length && !!pastos.length && !!retiros.length;
  if (mapearRetiros) {
    return lotes.map((lote) => {
      const pastoLote = pastos.find((pasto) => pasto.IdPasto === lote.IdPasto);
      if (pastoLote) {
        const retiroLote = retiros.find(
          (retiro) => retiro.IdRetiro === pastoLote.IdRetiro
        );
        if (retiroLote) {
          const { IdRetiro, Nome, NomeReduzido } = retiroLote;
          return {
            ...lote,
            retiro: {
              IdRetiro,
              Nome,
              NomeReduzido,
            },
          };
        } else {
          return lote;
        }
      }
      return lote;
    });
  }
  return lotes;
};

export const getLotesFiltradosPorTexto = (lotesFiltrados, filtroTexto) => {
  return funcoesInternas.filtraListaLotes(lotesFiltrados, filtroTexto);
};

export const funcoesInternas = {
  filtraListaLotes,
};

export const convertLivestockToLote = (livestock) => {
  if (!livestock || !livestock.farmId || !livestock.pastures) return [];

  const { farmId, pastures } = livestock;
  return _.flatMap(pastures, (pasture) =>
    pasture.livestocks.map((livestock) => ({
      IdEstadoLote: livestock.id,
      IdLote: livestock.id,
      IdCategoriaAnimal: livestock.animalCategoryId,
      IdPasto: livestock.pastureId,
      IdFazenda: farmId,
      Quantidade: livestock.quantity,
      IdObjetivo: findLivestockGoalId(livestock),
      IdPesoVisual: findLivestockWeightId(livestock, "VISUAL"),
      PesoVisual: findLivestockWeight(livestock, "VISUAL"),
      IdPesoMedido: findLivestockWeightId(livestock, "PESADO"),
      PesoMedido: findLivestockWeight(livestock, "PESADO"),
      IdPesoProjetado: findLivestockWeightId(livestock, "PROJETADO"),
      PesoProjetado: findLivestockWeight(livestock, "PROJETADO"),
      IdPesoObjetivo: findLivestockWeightId(livestock, "OBJETIVO"),
      PesoObjetivo: findLivestockWeight(livestock, "OBJETIVO"),
      IdPesoSaida: findLivestockWeightId(livestock, "SAIDA"),
      PesoSaida: findLivestockWeight(livestock, "SAIDA"),
      DataPesagem: findLivestockWeightDate(livestock, "PESADO"),
      DataPesagemVisual: findLivestockWeightDate(livestock, "VISUAL"),
      DataObjetivo: findLivestockWeightDate(livestock, "OBJETIVO"),
      DataOperacao: livestock.operationDate,
      DataValidade: livestock.endDate,
      Excluido: !!livestock.deletedAt,
      DataExclusao: livestock.deletedAt,
      DataCriacao: livestock.createdAt,
      UsuarioCriacao: livestock.creationUser,
    }))
  );
};

export const convertProjectedWeightToPesoProjetado = (projectedWeight) => {
  if (!projectedWeight) return null;

  return {
    IdLote: projectedWeight.livestockId,
    Janeiro: projectedWeight.january,
    Fevereiro: projectedWeight.february,
    Marco: projectedWeight.march,
    Abril: projectedWeight.april,
    Maio: projectedWeight.may,
    Junho: projectedWeight.june,
    Julho: projectedWeight.july,
    Agosto: projectedWeight.august,
    Setembro: projectedWeight.september,
    Outubro: projectedWeight.october,
    Novembro: projectedWeight.november,
    Dezembro: projectedWeight.december,
  };
};

const findLivestockWeight = (livestock, weightType) => {
  const weight = livestock.weights.find(
    (weight) => weight.weightType === weightType
  );
  return weight ? weight.weight : weight;
};

const findLivestockWeightDate = (livestock, weightType) => {
  const weight = livestock.weights.find(
    (weight) => weight.weightType === weightType
  );
  return weight ? weight.operationDate : weight;
};

const findLivestockWeightId = (livestock, weightType) => {
  const weight = livestock.weights.find(
    (weight) => weight.weightType === weightType
  );
  return weight ? weight.id : weight;
};

export const validateLivestockResponse = (response, onSucessCallback) => {
  if (!response || !response.data || !response.data.data) {
    return {
      data: [],
    };
  }
  return onSucessCallback(response);
};

const findLivestockGoalId = (livestock = {}) => {
  const { goal } = livestock;
  return goal ? goal.id : null;
};
