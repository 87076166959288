import React from "react";
import PropTypes from "prop-types";
import { isValidDate } from "shared/utils";

const formatDateTime = (date) => {
  return isValidDate(new Date(date))
    ? new Date(date)
        .toLocaleString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        })
        .replace(",", " às")
    : "";
};

export function TotalHerd({
  operationDate,
  total,
  categoryName,
  pastureName,
  customStyles,
  ...props
}) {
  const formattedDate = formatDateTime(operationDate);
  const animalText = total === 1 ? "animal" : "animais";

  return (
    <div style={{ ...styles.wrapper, ...customStyles }} {...props}>
      <p style={styles.title}>Total de animais no dia da coleta</p>
      <div style={styles.info}>
        {formattedDate && categoryName && pastureName && total !== null
          ? `No dia ${formattedDate}, havia ${total} ${animalText} da Categoria ${categoryName} no pasto ${pastureName}.`
          : "Carregando..."}
      </div>
    </div>
  );
}

TotalHerd.propTypes = {
  operationDate: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  categoryName: PropTypes.string.isRequired,
  pastureName: PropTypes.string.isRequired,
  customStyles: PropTypes.object,
};

const styles = {
  wrapper: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    fontFamily: "Roboto, sans-serif",
    maxHeight: "53px",
  },
  title: {
    fontSize: "12px",
    color: "#0000008A",
    margin: 0,
  },
  info: {
    padding: "6px 0 12px",
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.87)",
  },
};
